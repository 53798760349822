.spec_popup__button {
  cursor: pointer;
  text-decoration: underline;
}

.spec_popup__close {
  cursor: pointer;
  position: absolute;
  right: 10%;
  top: 25px;
}

.spec_popup__modal {
  visibility: hidden;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1001;
  background: #ffffff;
  top: 100%;
  transition-duration: 0.6s;
  transition-timing-function: ease-out;
  padding: 25px 10%;
  color: #000;
}

.opened .spec_popup__modal {
  visibility: visible;
  top: 0;
}

.spec_popup__container {
  margin: 0 auto;
  max-width: 1130px;
  height: 100%;
  display: flex;
  padding-top: 60px;
}

.spec_popup__image {
  flex: 1;
}

.spec_popup__image img {
  width: 100%;
}

.spec_popup__content {
  flex: 1;
  padding: 0 25px;
  overflow-y: auto;
  padding-bottom: 45px;
}
